@import "../../../App.scss";

.container {
  border-bottom: 3px solid red;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  background-color: black;
}

.title-container {
  display: flex;
  margin: 0 auto;
  width: 28.5em;
  align-items: center;
  justify-content: space-between;

}

.title-container h1 {
  font-size: 50px;
  font-family: $gt-pressura-bold;
  color: white;
  letter-spacing: 2.15px;
}

.Wlogo {
  width: 75px;
  height: 75px;
}
.logo {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .container {
    border-bottom: 1px solid blue;
    width: auto;
    margin: 0 auto;
    justify-content: space-around;
  }
  .title-container {
    width: 310px;
  }
  .title-container h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    border-bottom: 1px solid blue;
    width: auto;
    margin: 0 auto;
    justify-content: space-around;
  }
  .title-container {
    width: 83%;
  }
  .title-container h1 {
    font-size: 30px;
  }
}
