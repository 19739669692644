$gt-pressura-regular: GtPressuraRegular;
$gt-pressura-light: GtPressuraLight;
$gt-pressura-bold: GtPressuraBold;

.App {
  text-align: center;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /*color: white;*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*to remove the bullets for each entry on Deaths in 2019 article*/
li {
  list-style: none;
}

.tocright {
  display: none;
}

.body {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;


  //font-family: $gt-pressura-light;
  font-size: 22px;
  letter-spacing: 1.15px;

}

table.infobox.vcard {
  float: left;
  box-shadow: 0 3px 6px rgba(150, 148, 148, 0.16), 0 3px 6px rgba(150, 148, 148, 0.16);
  padding: 10px;
  margin: 0 15px;
}

table.infobox.biography.vcard {
  float: left;
  box-shadow: 0 3px 6px rgba(150, 148, 148, 0.16), 0 3px 6px rgba(150, 148, 148, 0.16);
  padding: 10px;
  margin: 0 15px;
}

#toc {

  position: fixed;
  max-width: 13%;
  font-size: 15px;
  overflow-y: scroll;
  height: 100%;
  float: left;
  left: 0px;
  top: 140px;

  /*float: left;*/
  /*position: fixed;*/
  /*margin: -400px -10% -200px -15%;*/
  /*max-width: 13%;*/
  /*font-size: 15px;*/
  /*height: 600px;*/
  /*overflow-y: scroll;*/
}

.mw-parser-output th[colspan="4"] {
  background-color: #e2e2e2 !important;
  border-radius: 2px;
  color: white;
}

.infobox.vcard th[colspan="2"] {
  background-color: #e2e2e2 !important;
  border-radius: 2px;
  color: white;
}

@media screen and (max-width: 1200px) {
  #toc {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .body {
    width: 90%
  }
  #toc {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .body {
    width: 99%
  }

  table.infobox.vcard {
    float: none;
    width: 95% !important;

  }

  table.infobox.biography.vcard {
    float: none;
    width: 95% !important;
  }

}

@media screen and (max-width: 480px) {
  .body {
    width: auto;
    font-size: 18px;
    margin-left: .5em;
    margin-right: .5em;
  }

  .body h1 {
    font-size: 35px;
  }

  tr {
    line-height: 1.2em;
  }

  p {
    line-height: 1.6em;
  }

  tbody {
    width: fit-content;
  }
  mw-parser-output {
    width: fit-content;
  }

  mw-parser-output.thumb.right {

  }

  table.infobox.biography.vcard {
       width: fit-content !important;
       font-size: 18px;
  }

  table.infobox vcard {
    width: fit-content !important;
    font-size: 18px;
  }

  ol.references {
    width: fit-content !important;
    height: auto !important;
  }

}

.body a {
  font-weight: bold;
  color: #376abd;
  text-decoration: none;

}

.body a:hover {
  text-decoration: underline;
}

body h3 {
  margin-bottom: 0px;
}

body ul {
  margin-top: -15px;
  margin-bottom: 25px;
}

body li {
  margin: 10px 0px;
}

body h1 {
  font-size: 45px;
}


/* Deaths by month style at the very bottom*/

#Deaths_by_month {
  margin: 0 0 20px 0 !important;
}

.plainlinks.hlist.navbar.mini {
  display: none;
}

.nowraplinks.hlist.mw-collapsible.autocollapse.navbox-inner {
  width: 100%
}

.navbox-columns-table tr {
  vertical-align: top;
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
}

.nowraplinks .navbox-subgroup {
  width: 100%
}

table.nowraplinks .navbox-subgroup {
  border-collapse: separate !important;
  border-spacing: 2px !important;
  border-color: grey !important;
}

.nowraplinks .navbox-subgroup tr {
  display: inline-block;

}

th.navbox-group {
  width: 50px !important;
  min-width: 50px !important;
  max-width: 50px !important;
}

.navbox-list ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
  list-style: circle;
  margin: -5px 30px 0px 0px;
}


/*style the referances*/
.reflist li {
  list-style: decimal;
}

ol.references {
  display: flex;
  width: 72%;
  flex-wrap: wrap;
  font-size: 14px;
  justify-content: space-between;
  flex-direction: column;
  height: 10000px;
}
