body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'GtPressuraRegular';
  src: local('GtPressura'), url(./assets/fonts/GT_Pressura/gt-pressura-regular.ttf) format('truetype');
}

@font-face {
  font-family: 'GtPressuraLight';
  src: local('GtPressura'), url(./assets/fonts/GT_Pressura/gt-pressura-light.ttf) format('truetype');
}

@font-face {
  font-family: 'GtPressuraBold';
  src: local('GtPressura'), url(./assets/fonts/GT_Pressura/gt-pressura-bold.ttf) format('truetype');
}
